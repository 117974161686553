<template>
  <CommonLayout>
    <div class="cabinet">
      <div class="cabinet__inner">
        <button
          class="button-default button-back achive-back"
          @click="$router.go(-1)"
        >
          <span></span>Назад
        </button>
        <div class="cabinet__head">
          <div class="cabinet__row">
            <div class="cabinet__col">
              <h1 class="title">Достижения</h1>
            </div>
          </div>
        </div>
        <div class="cabinet__body">
          <div class="achive">
            <div class="switcher" :class="{ active: type === 'available' }">
              <button
                :disabled="type === 'received'"
                @click="type = 'received'"
              >
                Уже получил
              </button>
              <button
                :disabled="type === 'available'"
                @click="type = 'available'"
              >
                Могу получить
              </button>
            </div>
            <div v-if="loading" class="achive-sceleton">
              <Loading position="center" />
            </div>
            <div v-else class="achive-content">
              <div class="achive-head">
                <div class="achive-head__group">
                  <div class="achive-head__title">
                    <template v-if="type === 'received'">
                      Получено {{ completedList.length }}/36
                    </template>
                    <template v-else>Могу получить</template>
                  </div>
                  <div class="achive-head__weeks">
                    Игровая неделя {{ currentWeek || "-" }}/<span>36</span>
                  </div>
                </div>
                <div v-show="type === 'received'" class="achive-head__progress">
                  <span
                    :style="{ width: `${(completedList.length / 36) * 100}%` }"
                  ></span>
                </div>
              </div>
              <div class="achive-body">
                <div v-if="type === 'received'">
                  <div v-if="completedList.length" class="achive-grid">
                    <div
                      v-for="(item, index) in completedList"
                      :key="index"
                      class="achive-grid__item"
                      :class="{ active: isAchievesActive(item) }"
                    >
                      <span
                        v-if="item.icon"
                        :style="{
                          'background-image': `url('/static/achieves/${item.icon}')`,
                        }"
                      ></span>
                      <Tooltip
                        type="fluid"
                        :msg="`
                        <p><strong>${item.title}</strong></p>
                        <p>${item.description}</p>`"
                      />
                    </div>
                    <!-- <div class="achive-grid__item">
                      <span></span>
                      <Tooltip
                        type="fluid"
                        msg="<p><strong>Все благодарности, которые вы не отправите сгорят 31.03.2022.</strong></p><p>Одному и тому же игроку можно отправить: 4 шт. до 31.03.2022</p>"
                      />
                    </div>
                    <div class="achive-grid__item">
                      <span></span>
                      <Tooltip
                        type="fluid"
                        msg="<p>Все благодарности, которые вы не отправите сгорят 31.03.2022.</p><p>Одному и тому же игроку можно отправить: 4 шт. до 31.03.2022</p>"
                      />
                    </div> -->
                  </div>
                  <div v-else class="achive-grid-empty">
                    На&nbsp;вкладке &laquo;Достижения&raquo; отображаются иконки
                    заработанных тобой достижений.<br />Как только
                    ты&nbsp;заработаешь первое, оно появится здесь.
                  </div>
                </div>
                <div v-else-if="type === 'available'" class="achive-list">
                  <div
                    v-for="(item, index) in inProgressList"
                    :key="index"
                    class="achive-list__item"
                  >
                    <div class="achive-list__group">
                      <div class="achive-list__column">
                        <div class="achive-list__pic">
                          <span
                            v-if="item.icon"
                            :style="{
                              'background-image': `url('/static/achieves/${item.icon}')`,
                            }"
                          ></span>
                        </div>
                        <div class="achive-list__progress">
                          <div class="achive-list__progress-bar">
                            <span
                              :style="{ width: triggerCurrentPercent(item) }"
                            ></span>
                          </div>
                          <div
                            v-if="triggerIsInfinity(item)"
                            class="achive-list__progress-text"
                          >
                            0/1
                          </div>
                          <div v-else class="achive-list__progress-text">
                            {{ triggerCurrent(item) }}/{{
                              triggerCurrentCount(item)
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="achive-list__column">
                        <div class="achive-list__title">{{ item.title }}</div>
                        <div class="achive-list__desc">
                          {{ item.description }}
                        </div>
                        <div v-if="item.coinReward" class="achive-list__desc">
                          Награда<img
                            src="@/assets/img/pic2/coin.svg"
                            width="16"
                            height="16"
                            alt=""
                          />
                          <strong>{{ item.coinReward | priceFilter }}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="achive-list__item">
                    <div class="achive-list__group">
                      <div class="achive-list__column">
                        <div class="achive-list__pic"><span></span></div>
                        <div class="achive-list__progress">
                          <div class="achive-list__progress-bar">
                            <span style="width: 30%"></span>
                          </div>
                          <div class="achive-list__progress-text">2/10</div>
                        </div>
                      </div>
                      <div class="achive-list__column">
                        <div class="achive-list__title">Серебряный Майор</div>
                        <div class="achive-list__desc">
                          Выиграй 30 поединков
                        </div>
                        <div class="achive-list__desc">
                          Награда<img
                            src="@/assets/img/pic2/coin.svg"
                            width="16"
                            height="16"
                            alt=""
                          />
                          <strong>1 500</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="achive-list__item">
                    <div class="achive-list__group">
                      <div class="achive-list__column">
                        <div class="achive-list__pic"><span></span></div>
                        <div class="achive-list__progress">
                          <div class="achive-list__progress-bar">
                            <span></span>
                          </div>
                          <div class="achive-list__progress-text">2/10</div>
                        </div>
                      </div>
                      <div class="achive-list__column">
                        <div class="achive-list__title">Серебряный Майор</div>
                        <div class="achive-list__desc">
                          Выиграй 30 поединков
                        </div>
                        <div class="achive-list__desc">
                          Награда<img
                            src="@/assets/img/pic2/coin.svg"
                            width="16"
                            height="16"
                            alt=""
                          />
                          <strong>1 500</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="achive-list__item">
                    <div class="achive-list__group">
                      <div class="achive-list__column">
                        <div class="achive-list__pic"><span></span></div>
                        <div class="achive-list__progress">
                          <div class="achive-list__progress-bar">
                            <span></span>
                          </div>
                          <div class="achive-list__progress-text">2/10</div>
                        </div>
                      </div>
                      <div class="achive-list__column">
                        <div class="achive-list__title">Серебряный Майор</div>
                        <div class="achive-list__desc">
                          Выиграй 30 поединков
                        </div>
                        <div class="achive-list__desc">
                          Награда<img
                            src="@/assets/img/pic2/coin.svg"
                            width="16"
                            height="16"
                            alt=""
                          />
                          <strong>1 500</strong>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<script>
import { mapGetters } from "vuex";
import { GetPlayerAchievements } from "@/api/PlayerAchievements.js";

import CommonLayout from "@/layout/CommonLayout.vue";
import Tooltip from "@/components/Tooltip.vue";
import Loading from "@/components/Loading.vue";

export default {
  components: {
    CommonLayout,
    Tooltip,
    Loading,
  },
  computed: {
    ...mapGetters([
      "getGame",
      "getProfile",
      "getProfileCurrentIsland",
      "getTeam",
    ]),
    currentWeek() {
      let week = this.getGame.currentWeek;
      if (week >= 35) {
        week = 36;
      } else {
        week = week + 1;
      }
      return week;
    },
  },
  data() {
    return {
      loading: false,
      type: "received",
      completedList: [],
      inProgressList: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const { userID } = this.$route.params;
      GetPlayerAchievements({ playerId: userID }).then((resp) => {
        const { completed, inProgress } = resp.data;
        this.completedList = completed || [];
        this.inProgressList = inProgress || [];

        // ачивку с двумя триггерами далаем бексонечной для отображения 0/1
        // current сбрасываем до 0 для сортировки по прогрессу
        this.inProgressList.forEach((e) => {
          if (e.triggers.length > 1) {
            e.triggers[0].isInfinite = true;
            e.triggers.forEach((trigger) => {
              trigger.current = 0;
            });
          }
        });

        this.inProgressList = this.inProgressList.sort((a, b) => {
          return (
            b.triggers[b.triggers.length - 1].current -
            a.triggers[a.triggers.length - 1].current
          );
        });
      });
    },
    getTrigger(items) {
      if (items.length === 1) {
        return items[0];
      } else if (items.length) {
        return items[0];
        // const active = items.filter((e) => e.count > e.current);
        // return active.length
        //   ? active[active.length - 1]
        //   : items[items.length - 1];
      }
      return null;
    },
    triggerCurrentPercent(item) {
      const trigger = this.getTrigger(item.triggers);
      if (trigger) {
        if (trigger.isInfinite) {
          return 0;
        }

        let percent = (trigger.current / trigger.count) * 100;

        if (percent > 100) {
          percent = 100;
        }

        return percent + "%";
      }
      return 0;
    },
    triggerCurrent(item) {
      const trigger = this.getTrigger(item.triggers);

      if (trigger) {
        return trigger.current;
      }
      return 0;
    },
    triggerCurrentCount(item) {
      const trigger = this.getTrigger(item.triggers);

      if (trigger) {
        return trigger.count;
      }
      return 0;
    },
    triggerIsInfinity(item) {
      const trigger = this.getTrigger(item.triggers);

      if (trigger) {
        return trigger.isInfinite;
      }
      return false;
    },
    isAchievesActive(data) {
      const { achievementId, achievementLevel } = this.$route.query;

      if (
        +achievementId === data.achievementId &&
        +achievementLevel === data.level
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.achive {
  width: 100%;
  &-back {
    position: absolute;
    top: 15px;
    left: 80px;
  }
  &-head {
    margin-top: 40px;
    &__group {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    &__title {
      font-size: 40px;
      line-height: 46px;
      color: #5cffed;
    }
    &__weeks {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #50ffeb;
      margin-bottom: 3px;
    }
    &__progress {
      border: 2px solid #50ffeb;
      border-radius: 30px;
      height: 16px;
      margin-top: 10px;
      span {
        background: #50ffeb;
        border-radius: 30px;
        display: block;
        height: 100%;
        width: 0;
        box-shadow: 0px 0px 21px rgba(255, 243, 243, 0.25), 0px 0px 7px #00ffff;
      }
    }
  }
  &-body {
    margin-top: 30px;
  }
  &-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(6, 162px);
    justify-content: space-between;
    &__item {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg width='167' height='166' viewBox='0 0 167 166' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m164.864 53.7295-7.616-10.1131V25.3111l7.616-7.6021v119.309l-27.025 27.029H14.8574l7.9048-7.903h23.0779l6.2266 7.903M2 112.302l7.6155 10.113v18.306L2 148.334V29.0144L29.0245 1.9961H152.006l-7.905 7.903h-23.078l-6.226-7.903' stroke='%2350FFEB' stroke-width='2.54' stroke-miterlimit='10'/%3E%3C/svg%3E");
      width: 162px;
      height: 162px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &.active {
        background-image: url("data:image/svg+xml,%3Csvg width='167' height='166' viewBox='0 0 167 166' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.5 1H166v135.5l-13 13-15.5 15.5H1V29L28.5 1Z' fill='%2300FFFE' fill-opacity='.15'/%3E%3Cpath d='m164.864 53.7299-7.616-10.1131V25.3115l7.616-7.6021V137.018l-27.025 27.03H14.8574l7.9048-7.903h23.0779l6.2266 7.903M2 112.303l7.6155 10.113v18.305L2 148.335V29.0149L29.0245 1.9966H152.006l-7.905 7.903h-23.078l-6.226-7.903' stroke='%2350FFEB' stroke-width='2.54' stroke-miterlimit='10'/%3E%3C/svg%3E");
      }
      > span {
        width: 60%;
        height: 60%;
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    }
    &-empty {
      background: rgba(0, 255, 254, 0.03);
      border-radius: 2px;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      align-items: center;
      text-align: center;
      color: rgba(173, 229, 217, 0.7);
      min-height: 40vh;
      padding: 0 200px;
    }
  }
  &-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    &__item {
      margin-bottom: 40px;
    }
    &__group {
      display: flex;
    }
    &__column {
      &:nth-child(1) {
        width: 162px;
        margin-right: 40px;
      }
      &:nth-child(2) {
        width: 300px;
      }
    }
    &__pic {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg width='167' height='166' viewBox='0 0 167 166' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m164.864 53.7295-7.616-10.1131V25.3111l7.616-7.6021v119.309l-27.025 27.029H14.8574l7.9048-7.903h23.0779l6.2266 7.903M2 112.302l7.6155 10.113v18.306L2 148.334V29.0144L29.0245 1.9961H152.006l-7.905 7.903h-23.078l-6.226-7.903' stroke='%2350FFEB' stroke-width='2.54' stroke-miterlimit='10'/%3E%3C/svg%3E");
      width: 162px;
      height: 162px;
      display: flex;
      justify-content: center;
      align-items: center;
      > span {
        width: 60%;
        height: 60%;
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    }
    &__progress {
      margin-top: 20px;
      width: 140px;
      margin-left: 11px;
      text-align: center;
      &-bar {
        border: 2px solid #50ffeb;
        border-radius: 30px;
        height: 16px;
        span {
          background: #50ffeb;
          border-radius: 30px;
          display: block;
          height: 100%;
          width: 0;
          box-shadow: 0px 0px 21px rgba(255, 243, 243, 0.25),
            0px 0px 7px #00ffff;
        }
      }
      &-text {
        margin-top: 5px;
        font-size: 18px;
        line-height: 26px;
        color: #ade5d9;
      }
    }
    &__title {
      margin-top: 25px;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
    }
    &__desc {
      margin-top: 20px;
      font-size: 18px;
      line-height: 23px;
      img {
        margin-left: 10px;
        margin-right: 4px;
        vertical-align: middle;
        margin-bottom: 2px;
      }
    }
  }
}
</style>
